import { graphql } from "gatsby";
import React from "react";
import { BlogList, Layout, Pager, Seo } from "../components";
import styled from "@emotion/styled";
import { mq } from "../styles/GlobalStyles";
import { IGatsbyImageData } from "gatsby-plugin-image";

interface MarkdownRemarkData {
  id: string;
  excerpt: string;
  frontmatter: {
    slug: string;
    date: Date;
    title: string;
    featuredImgAlt: string;
    hero: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    heroAlt: string;
  };
  featuredImg?: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface PageInfo {
  currentPage: number;
  hasNextPage: boolean;
  itemCount: number;
  hasPreviousPage: boolean;
  pageCount: number;
  perPage: number;
  totalCount: number;
}

interface IndexData {
  allMarkdownRemark: {
    pageInfo: PageInfo;
    edges: [
      {
        node: MarkdownRemarkData;
      }
    ];
  };
}

const H1 = styled.h1(
  mq({
    fontSize: ["2em", "3.5em"],
    color: "#2E3C4D",
    marginBottom: "12px",
  })
);

export default function BlogPage({ data }: { data: IndexData }): JSX.Element {
  const { pageInfo, edges } = data.allMarkdownRemark;

  return (
    <Layout>
      <>
        <Seo title="ブログ" />
        <H1>Tech Blog</H1>
        <BlogList lists={edges} />
        <Pager pageInfo={pageInfo} />
      </>
    </Layout>
  );
}

export const query = graphql`
  query ($limit: Int, $skip: Int) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        itemCount
        hasPreviousPage
        pageCount
        perPage
        totalCount
      }
      edges {
        node {
          id
          excerpt(truncate: true)
          featuredImg {
            childImageSharp {
              gatsbyImageData(
                width: 337
                height: 178
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          frontmatter {
            title
            slug
            date(formatString: "YYYY-MM-DD")
            featuredImgAlt
            hero {
              childImageSharp {
                gatsbyImageData(
                  width: 337
                  height: 178
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            heroAlt
          }
        }
      }
    }
  }
`;
